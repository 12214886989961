import React from 'react';
import './cardAppIndicator.scss';
import { Grid, IconButton, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WarningIcon from '@mui/icons-material/Warning';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function CardAppIndicator({ data }) {
  console.log(data);
  return (
    <Grid container xs={12} className='card-app-container'>
      {data?.map(ele => (
        <Grid className='card-element' key={ele.id} item>
          <div>
            <Typography fontWeight={'800'} variant='p'>{ele.name}</Typography>
          </div>
          <div className='element-left-pannel'>
            {ele.status === 'running' && <CheckCircleIcon color="success" />}
            {ele.status === 'error' && <WarningIcon sx={{color: "#FC0"}} />}
            {ele.status === 'stopped' && <RemoveCircleIcon color="error" />}
            <Typography className='element-description' variant='p'>
              {ele.description}
            </Typography>

            <IconButton>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default CardAppIndicator;
