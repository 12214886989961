import React, { FunctionComponent } from 'react';
import AppLayout from '../../../layout/appLayout';
import { Grid, Typography } from '@mui/material';
import CardAppIndicator from '../../../components/cardAppIndicator/cardAppIndicator';
import './home.scss';

function Home() {
  const cardItem = [
    {
      id: 1,
      name: 'TIM',
      status: 'running',
      description:'no reported issue'
    },
    {
      id: 2,
      name: 'ROOTS',
      status: 'error',
      description:'no reported issue'
    },
    {
      id: 3,
      name: 'LeafOut',
      status: 'stopped',
      description:'no reported issue'
    },
    {
      id: 3,
      name: 'LIVE',
      status: 'stopped',
      description:'no reported issue'
    },
  ];
  return (
    <AppLayout>
      <Grid container className='container'>
        <Grid item>
          <Typography variant='h4'>Text about the app will go here</Typography>
        </Grid>
        <Grid item  className='card-container'>
        <Typography  marginBottom={1} variant='p' fontWeight={'600'}>Finance</Typography>
        <CardAppIndicator data={cardItem}/>
        </Grid>
        <Grid item  className='card-container'>
        <Typography  marginBottom={1} variant='p' fontWeight={'600'}>Transmisson</Typography>
        <CardAppIndicator data={cardItem}/>
        </Grid>
      </Grid>
    </AppLayout>
  );
}

export default Home;
