import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import Home from './pages/app/home/home';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      // loader: rootLoader,
      children: [
      ],
    },  
    
  ]);
  return (
   
    <RouterProvider router={router} />
    );
}

export default App;
