import React, {  FunctionComponent, ReactElement } from 'react'
import Header from '../components/common/header/header'
import Footer from '../components/common/footer/footer'
import { Box } from '@mui/material'

// type Component = React.FC<any> | React.LazyExoticComponent<React.FC<any>>


// interface AppLayoutProps {
//     children:  ReactElement
// }
 
const AppLayout= ({children: AppLayoutProps}) => {
    return ( 
        <>
        <Header/>
       <Box component="main"  sx={{ mt:15 , backgroundColor:'white'}}>
       {AppLayoutProps}
       </Box>
       <Footer/>
        </>
     );
}
 
export default AppLayout;
  


